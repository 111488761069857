import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyAHmsVotAj-1e9bwF_pIMNqIaEgI_qP9YU",
    authDomain: "cafeteriarua1101.firebaseapp.com",
    projectId: "cafeteriarua1101",
    storageBucket: "cafeteriarua1101.appspot.com",
    messagingSenderId: "899089253657",
    appId: "1:899089253657:web:2ffde5156e734ed960f188",
    measurementId: "G-0DMGJZR72C"
};

const firebaseApp = initializeApp(firebaseConfig)
export default firebaseApp