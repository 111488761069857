<template>
  <v-container>
    <v-card
      width="350"
      :height="cardData.position ? '' : '210'"
      class="mx-auto credit-card"
      style="background-color:#191970;color: #fff"
    >
      <div v-if="cardData.position">
        <v-layout row wrap class="card-row">
          <v-flex xs6>
          </v-flex>
          <v-flex xs6 class="text-lg-right">
            <!-- <h4>{{ cardData.type || '' }}</h4> -->
            <img width="50" v-if="!cardData.type" :src="'/assets/chip.png'" />
            <img width="50" v-if="cardData.type" :src="'/assets/' + cardData.type + '.png'" />
         </v-flex>
        </v-layout>

        <v-layout row wrap class="card-row">
          <v-flex xs12 class="text-md-center">
            <h3>{{ cardData.number || 'XXXX-XXXX-XXXX-XXXX' }}</h3>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="card-row">
          <v-flex xs8 class="text-md-center">
            <h3>{{ cardData.name || 'SEU NOME AQUI' }}</h3>
          </v-flex>
          <v-flex xs4 class="text-md-center">
            <h3 class="ml-3">{{ cardData.validate || 'XX/XX' }}</h3>
          </v-flex>
        </v-layout>
      </div>
      <div v-else>
        <v-layout row wrap style="border: 15px solid black">
        </v-layout>
        <v-layout row wrap class="card-row">
          <v-flex  xs12 class="text-md-right">
            <h3 class="ml-1">{{ cardData.cvv || '' }}</h3>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
    <v-card
    max-width="350"
    class="mx-auto"
    style="padding: 1%"
    >
    <v-layout style="display: block;">
      <v-flex xs12>
        <v-radio-group v-model="cardData.credit" inline class="text-center">
          <v-radio
            hide-details="true"
            label="Crédito"
            value="CREDIT"
          ></v-radio>
          <v-radio
            hide-details="true"
            label="Débito"
            value="DEBIT"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="cardData.number"
          label="Número do cartão"
          outlined
          v-mask="mask.credit_card"
          placeholder="XXXX-XXXX-XXXX-XXXXX"
          append-icon="mdi-credit-card"
          @focus="cardData.position = true"
          @keyup="buscarBandeira()"
        ></v-text-field>
        <v-text-field
          v-model="cardData.name"
          label="Titular do cartão"
          outlined
          append-icon="mdi-account"
          @focus="cardData.position = true"
        ></v-text-field>
        <v-layout style="display: block;">
          <v-flex xs5>
            <v-text-field
              v-model="cardData.validate"
              label="Validade"
              outlined
              v-mask="mask.date"
              placeholder="XX/XX"
              append-icon="mdi-calendar"
              @focus="cardData.position = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs2></v-flex>
          <v-flex xs5 >
            <v-text-field
              v-model="cardData.cvv"
              label="CVV"
              outlined
              v-mask="mask.cvv"
              placeholder="XXXX"
              append-icon="mdi-numeric"
              @focus="cardData.position = false"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout style="display: block;">
          <v-flex xs5 >
            Digite seu endereço abaixo <br><br>
          </v-flex>

          <v-flex xs5 >
            <v-text-field
              v-model="endereco.cep"
              label="CEP"
              outlined
              v-mask="mask.cep"
              placeholder="XXXX-XXX"
              append-icon="mdi-numeric"
              @focus="cardData.position = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs5 >
            <v-text-field
              v-model="endereco.rua"
              label="RUA"
              outlined
              placeholder="Rua 1101, 140 Casa"
              append-icon="mdi-map-marker"
              @focus="cardData.position = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs5 >
            <v-text-field
              v-model="endereco.bairro"
              label="BAIRRO"
              outlined
              placeholder="Centro"
              append-icon="mdi-map-marker"
              @focus="cardData.position = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs5 >
            <v-text-field disabled
              v-model="endereco.cidade"
              label="CIDADE"
              outlined
              placeholder="Balneário Camboriu"
              append-icon="mdi-map-marker"
              @focus="cardData.position = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs4></v-flex>
          <v-flex xs4>
            <v-btn color="primary" block @click="sendPayment">
              Pagar
            </v-btn>
          </v-flex>
          <v-flex xs4></v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
  </v-container>
</template>

<script>

import { mask } from 'vue-the-mask'
import cardValidator from 'card-validator'
import axios from 'axios'

axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const myaxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": '*' ,
    "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept ',
    "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE" ,
    "Access-Control-Max-Age": 3600,
  }
})

export default {
  directives: { mask },
  data: () => ({
    mask: {
      credit_card: '####-####-####-#######',
      date: '##/##',
      cvv: '####',
      cep: '#####-###',
    },
    cardData: {
      credit: 'CREDIT',
      type: 'Mastercard',
      number: '5448280000000007',
      name: 'John Snow',
      validate: '01/23',
      cvv: '123',
      position: true
    },
    endereco: {
      cep: '88331-405',
      rua: 'Rua Cecília Meireles, 5',
      cidade: 'Balneário Camboriú',
      bairro: 'Praia dos Amores',
    }
  }),
  methods: {
    buscarBandeira() {
      const bandeira = cardValidator.number(this.cardData.number);
      if(!bandeira.card)
        this.cardData.type = ''
      if (bandeira.card)
        this.cardData.type = bandeira.card.niceType;
    },
    sendPayment() {
      myaxios
        //  .get("https://us-central1-cafeteriarua1101.cloudfunctions.net/getTestRequests")
        //  .then((res) => {
        //    this.user = res.data;
        //  })
        //  .catch((error) => {
        //    console.log(error);
        //  });
        .post("https://us-central1-cafeteriarua1101.cloudfunctions.net/getTestRequests", 
          { cardinfo: this.cardData }
        )
    .then((response) => {
         console.log('Pagamento efetuado', response)
         // fazer request do endereço
         myaxios.post("https://us-central1-cafeteriarua1101.cloudfunctions.net/getTestRequests", 
            {endereco: this.endereco}
          )
          .then((response) => {
              console.log('Pagamento efetuado', response)
              // fazer request do endereço
              
          })
          .catch((error) => {
              console.log(error);
          });

    })
    .catch((error) => {
         console.log(error);
    });
     },
  }
};
</script>

<style scoped>
.credit-card{
  padding: 1.5%;
  margin: 1%;
  border-radius: 10px;
}
.card-row{
  margin: 8% 0 0 0;
}
</style>