import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

import colors from 'vuetify/lib/util/colors'
import { md3 } from 'vuetify/blueprints'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { fa } from 'vuetify/iconsets/fa'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files

export default createVuetify({
    blueprint: md3,
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        mdi,
        fa,
      },
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: colors.brown,
            secondary: colors.green,
            accent: colors.teal,
            error: colors.red,
            warning: colors.purple.darken1,
            info: colors.indigo,
            success: colors.green.lighten2,
          }
        }
      },
    },
})

// import VueCardFormat from 'vue-credit-card-validation';
const app = createApp(App)
app.use(vuetify)
// app.use(VueCardFormat)

app.config.productionTip = false

app.mount('#app')

