<template>
  <v-app-bar
      color="brown-darken-3"
      density="compact"
      height="80"
    >
      <v-app-bar-title>Go Coffe Rua 1101 <br/>Faça seu pedido</v-app-bar-title>

      <template v-slot:append>

        <span v-if="carrinho.length">  
          <v-btn class="text-none" stacked @click.stop="drawer = !drawer;">
            <v-badge :content="carrinho.length" color="success">
              <v-icon>mdi-cart</v-icon>
            </v-badge>
          </v-btn>
        </span>

        <span v-if="carrinho.length == 0">  
          <v-btn icon="mdi-cart-outline" @click.stop="drawer = !drawer"></v-btn>
        </span>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="selectedTab" grow center-active="true" 
        bg-color="brown-lighten-1"
        align-tabs="center">
          <v-tab @click="getProdutosDaCategoria(categoria.id)" v-for="categoria in categorias" :key="categoria.id">
            {{ categoria.nome }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer app location="right" floating temporary bottom v-model="drawer" width="50%">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Meu pedido </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item lines="3" v-for="item, index in carrinho" :key="item.id" link>
          <v-list-item-content>
            <v-list-item-title>
              <v-col>
                <v-row>
                  {{ item.quantidade }}X {{ item.produto.nome }} ({{ item.tamanho }})
                </v-row>
                <v-row>
                  <v-list-item-icon>
                    <v-avatar class="ma-1" size="50" rounded="0">
                      <v-img :src="item.produto.imagem" aspect-ratio="1" />
                    </v-avatar>
                  </v-list-item-icon>
                </v-row>
              </v-col>
              
              {{ new Intl.NumberFormat("pt-BR",
                        { style: "currency", currency: "BRL" }).format(item.valor_total) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-for="extra in item.extras" :key="extra.index">
                - {{ extra }}
              </div>
            </v-list-item-subtitle>
            
            <v-btn
              color="grey-lighten-1"
              icon="mdi-delete"
              variant="text"
              @click="removerItemCarrinho(index)"
            ></v-btn>

          </v-list-item-content>
          <v-divider></v-divider>

        </v-list-item>
        
        
        Total do pedido:  {{ new Intl.NumberFormat("pt-BR",
                        { style: "currency", currency: "BRL" }).format(carrinhoTotal) }}
        <!-- <v-btn class="mt-2" prepend-icon="mdi-check-circle">
          <template v-slot:prepend>
            <v-icon color="success"></v-icon>
          </template>

          Finalizar pedido
        </v-btn> -->
        <v-expansion-panels>
          <!-- <v-expansion-panel v-if="!this.isUserLoggedIn">
              <v-expansion-panel-title expand-icon="mdi-login">
                Fazer Login
              </v-expansion-panel-title>
            <v-expansion-panel-text>
              
            </v-expansion-panel-text>
          </v-expansion-panel> -->
          <v-expansion-panel v-if="this.carrinhoTotal">
              <v-expansion-panel-title expand-icon="mdi-check">
                Finalizar pedido
              </v-expansion-panel-title>
            <v-expansion-panel-text>
              <BaseCard />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import BaseCard from '@/components/BaseCard'

export default {
  name: 'AppBar',
  components: {
    BaseCard,
  },
  props: {
    categorias:[],
    carrinho: [],
    carrinhoTotal: null,
  },
  data: () => ({
    selectedTab: null,
    drawer: false,
    // isUserLoggedIn: false,
    // loggedUser: null,
  }),
  created() {
    
  },
  methods: {
    getProdutosDaCategoria(categoria) {
     this.$emit('mudouCategoria', categoria)
    },
    removerItemCarrinho(index) {
      this.$emit('removeuItemCarrinho', index)
    },
  },
}
</script>

<style scoped>
a {
  color: #42b983;
}
</style>
