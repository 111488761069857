<!-- eslint-disable no-unused-vars -->
<template>
  <v-layout>
    
    <AppBar ref="myAppBar"
    :categorias="this.categorias"
    :carrinho="this.carrinho"
    :carrinhoTotal="this.carrinhoTotal"
    @mudouCategoria="getProdutosDaCategoria($event)"
    @removeuItemCarrinho="removerItemCarrinho($event)"
    />

    <v-main>
      <v-container fluid>
          <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4" xxl="3" v-for="produto in produtosLista" :key="produto.id">
            <v-card class="ma-3" elevation="10" color="brown-lighten-4">
              <v-row>
                <v-col cols="4">
                  <v-avatar class="ma-1" size="100" rounded="0">
                    <v-img :src="produto.imagem" aspect-ratio="1" />
                  </v-avatar>
                </v-col>
                <v-col cols="8" class="text-left">
                  <v-card-title class="">{{produto.nome}}</v-card-title>
                  <v-card-text>
                    {{produto.descricao}}
                    <br />
                  </v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-title color="brown-lighten-2">
                      <v-row no-gutters>
                        <v-col cols="9" class="d-flex justify-start">
                          Customizar / Opcionais
                        </v-col>
                      </v-row>
                  </v-expansion-panel-title>

                  <v-expansion-panel-text>
                    <!-- leite -->
                    <span v-if="produto.extra_leite">
                      Leite
                      <v-radio-group v-model="extra_leite" >
                        <v-radio
                          @click="radioClicked($event, 'extra_leite', produto, extra_leite)"
                          hide-details="true"
                          label="Integral + R$ 0,00"
                          value="leite-0"
                        ></v-radio>
                        <v-radio
                        @click="radioClicked($event, 'extra_leite', produto, extra_leite)"
                          hide-details="true"
                          label="Zero Lactose + R$ 2,00"
                          value="leite-2"
                        ></v-radio>
                        <v-radio
                        @click="radioClicked($event, 'extra_leite', produto, extra_leite)"
                          hide-details="true"
                          label="Aveia + R$ 4,50"
                          value="leite-4.5"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>
                    
                    <!-- canela -->
                    <span v-if="produto.extra_canela">
                      Canela
                      <v-radio-group v-model="extra_canela" inline>
                        <v-radio
                          hide-details="true"
                          label="Com Canela"
                          value="radio-1"
                        ></v-radio>
                        <v-radio
                          hide-details="true"
                          label="Sem Canela"
                          value="radio-2"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>

                    <!-- chantilly -->
                    <span v-if="produto.extra_chantilly">
                      Chantilly
                      <v-radio-group v-model="inline" inline>
                        <v-radio
                          hide-details="true"
                          label="Com Chantilly"
                          value="radio-1"
                        ></v-radio>
                        <v-radio
                          hide-details="true"
                          label="Sem Chantilly"
                          value="radio-2"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>

                    <!-- calda -->
                    <span v-if="produto.extra_cobertura">
                      Calda / Cobertura
                      <v-radio-group v-model="inline" inline>
                        <v-radio
                          hide-details="true"
                          label="Com Calda"
                          value="radio-1"
                        ></v-radio>
                        <v-radio
                          hide-details="true"
                          label="Sem Calda"
                          value="radio-2"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>

                    <!-- sem café -->
                    <span v-if="produto.extra_cafe">
                      Café
                      <v-radio-group v-model="inline" inline>
                        <v-radio
                          hide-details="true"
                          label="Com Café"
                          value="radio-1"
                        ></v-radio>
                        <v-radio
                          hide-details="true"
                          label="Sem Café"
                          value="radio-2"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>

                    <!-- matcha agua -->
                    <span v-if="produto.extra_matcha">
                      Leite ou Água
                      <v-radio-group v-model="inline" inline>
                        <v-radio
                          hide-details="true"
                          label="Leite"
                          value="radio-1"
                        ></v-radio>
                        <v-radio
                          hide-details="true"
                          label="Água"
                          value="radio-2"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mb-1"></v-divider>
                    </span>

                    <!-- sem xp limão -->
                    <!-- Syrup Limão Siciliano
                    <v-radio-group v-model="inline" inline>
                      <v-radio
                        hide-details="true"
                        label="Com Syrup"
                        value="radio-1"
                      ></v-radio>
                      <v-radio
                        hide-details="true"
                        label="Sem Syrup"
                        value="radio-2"
                      ></v-radio>
                    </v-radio-group>
                    <v-divider class="mb-1"></v-divider> -->

                    <!-- adicionais padrão -->
                    <span v-if="produto.extra_adicionais">
                      Adicionais (opcional)
                      <v-row justify="space-around" class="ma-4">
                        <v-checkbox
                        v-model="extra_cafe"
                        @click="checkBoxClicked($event, 'extra_cafe', produto, 3.5)"
                        hide-details="true" density="compact" label="Dose de café + R$ 3,50"></v-checkbox>
                        <v-checkbox
                        v-model="extra_chantilly"
                        @click="checkBoxClicked($event, 'extra_chantilly', produto, 3.5)"
                        hide-details="true" density="compact" label="Chantilly + R$ 3,50"></v-checkbox>
                        <v-checkbox
                        v-model="extra_marshmallow"
                        @click="checkBoxClicked($event, 'extra_marshmallow', produto, 4)"
                        hide-details="true" density="compact" label="Marshmallow + R$ 4,00"></v-checkbox>
                        <v-checkbox
                        v-model="extra_espuma"
                        @click="checkBoxClicked($event, 'extra_espuma', produto, 2)"
                        hide-details="true" density="compact" label="Espuma (Crema) do leite + R$ 2,00"></v-checkbox>
                        <span v-if="produto.extra_queijo">
                          <v-checkbox
                          v-model="extra_queijo"
                          @click="checkBoxClicked($event, 'extra_queijo', produto, 2)"
                          hide-details="true" density="compact" label="Dobro de Cheddar + R$ 2,00"></v-checkbox>
                        </span>
                        <span v-if="produto.extra_presunto">
                          <v-checkbox
                          v-model="extra_presunto"
                          @click="checkBoxClicked($event, 'extra_presunto', produto, 2)"
                          hide-details="true" density="compact" label="Dobro de Presunto + R$ 2,00"></v-checkbox>
                        </span>
                      </v-row>
                    </span>
                  </v-expansion-panel-text>

                </v-expansion-panel>
              </v-expansion-panels>

              <v-divider></v-divider>
              <div class="ma-2">Escolha o tamanho</div>

              <v-card-actions class="d-flex align-center justify-center">

                <v-row align="center" align-content="space-around">

                  <v-col cols="6">
                    <v-radio-group inline hide-details="true" class="ma-0 pa-0">
                      <v-radio v-for="(tamanho, index) in produto.tamanho" :key="index"
                        :label="tamanho"
                        v-model="this.tamanhos"
                        :value="produto.valor[index]"
                        @click="radioClicked($event, 'produto', produto, index)"
                      ></v-radio>
                    </v-radio-group>
                    <v-divider vertical="true" class="ma-1"></v-divider>
                  </v-col>

                  <v-col cols="5">
                    <v-row align="center" align-content="space-around">
                      <v-col cols="4">
                        <v-btn size="small" color="deep-purple-accent" @click="recalcularQuantidade(produto,'menos')">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field class="ma-0 pa-0" single-line density="comfortable" disabled
                          v-model="this.quantidades[produto.id]"
                            hide-details
                            type="number"
                            variant="outlined">
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn size="small" color="deep-purple-accent" @click="recalcularQuantidade(produto,'mais')">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row  align="center" align-content="space-between">
                      <v-col>
                        <v-btn
                        color="deep-purple-accent"
                        @click="adicionarAoCarrinho(produto,this.quantidades[produto.id])">
                        <v-icon>mdi-cart-plus</v-icon>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span v-if="this.valores_finais[produto.id] > 0">
                        {{ new Intl.NumberFormat("pt-BR",
                        { style: "currency", currency: "BRL" }).format(this.valores_finais[produto.id]) }}
                        </span>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-col>

                </v-row>
                  
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4" xxl="3">
            
                Login aqui
             
          </v-col>
        </v-row> -->
      </v-container>
    </v-main>
    
  </v-layout>
</template>

<script>
import {  query, collection, getDocs, orderBy } from "firebase/firestore"
import firebaseApp from './firebase/init.js'
import AppBar from './components/AppBar.vue'
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// eslint-disable-next-line no-unused-vars
const auth = getAuth(firebaseApp)
const db = getFirestore()

// const exampleRef = collection(db, 'exemplos')

export default {
  name: 'App',
  components: {
    AppBar,
  },
  data: () => ({
    inline: 'radio-1',
    extra_leite: 'leite-0',
    extra_cafe: false,
    extra_chantilly: false,
    extra_marshmallow: false,
    extra_espuma: false,
    extra_queijo: false,
    extra_presunto: false,
    carrinho: [],
    categorias: [],
    produtos: [],
    produtosLista: [],
    tamanhos: [],
    valores: [],
    adicionais: [],
    valores_finais: [],
    quantidades: [],
    tamanhosSelecionados: [],
    carrinhoTotal: 0,
  }),
  created() {
    // console.log('created')
    this.getProdutos()
    this.getCategorias()
  },
  mounted() {
  },
  computed: { 
  },
  watch: {
  },
  methods: {    
    // async createDocExample() {
    //   const dataObj = {
    //     firstName: 'John',
    //     lastName: 'Doe',
    //     dob: '1990'
    //   }
    //   const docRef = await addDoc(exampleRef, dataObj)
    //   console.log('Document was created with ID:', docRef.id)
    // },
    async getCategorias() {
      this.categorias = [];
      const querySnap = await getDocs(query(collection(db, 'categorias'), orderBy('ordem')));
      querySnap.forEach((doc) => {
        this.categorias.push({id: doc.id, ...doc.data()})
      })
    },
    async getProdutos() {
      this.produtos = [];
      const querySnap = await getDocs(query(collection(db, 'produtos'), orderBy('ordem')));
      querySnap.forEach((doc) => {
        this.produtos.push({id: doc.id, ...doc.data()})
        this.quantidades[doc.id] = 1
      })
      this.getProdutosDaCategoria('hfvpuRhM8xNPuZQvh9xS')
    },
    async getProdutosDaCategoria(categoria) {
      this.produtosLista = this.produtos.filter((doc) => {
          if (doc.categoria === categoria){
              return {id: doc.id, ...doc}
          }
        }
      )
    },
    async removerItemCarrinho(itemIndex) {
      console.log('item pra remover de verdade = ', itemIndex)
      // delete this.carrinho[itemIndex]
      // const index = this.carrinho.indexOf(itemIndex)
      let newCart = []
      for (let index = 0; index < this.carrinho.length; index++) {
        if (index == itemIndex){
          this.carrinhoTotal -= this.carrinho[index].valor_total
        } else {
          newCart.push(this.carrinho[index])
        }
      }
      console.log(newCart)
      this.carrinho = newCart
      
    },
    async setProdutosCarrinho(produto) {
      // produto.extras_descricao = extras_descricao
      this.carrinho.push(produto)
      console.log(produto)
    },
    adicionarAoCarrinho(produto, quantidade) {

      let extras_descricao = []
      if (this.adicionais[produto.id + '-extra_leite'] == '2') {
        extras_descricao.push('leite zero lactose')
      } else if (this.adicionais[produto.id + '-extra_leite'] > 2) {
        extras_descricao.push('leite de aveia')
      }
      if (this.adicionais[produto.id + '-extra_cafe'] > 0) {
        extras_descricao.push('dose extra de café')
      }
      if (this.adicionais[produto.id + '-extra_chantilly'] > 0) {
        extras_descricao.push('chantilly extra')
      }
      if (this.adicionais[produto.id + '-extra_marshmallow'] > 0) {
        extras_descricao.push('marshmallow extra')
      }
      if (this.adicionais[produto.id + '-extra_espuma'] > 0) {
        extras_descricao.push('crema extra de leite')
      }
      if (this.adicionais[produto.id + '-extra_queijo'] > 0) {
        extras_descricao.push('extra de queijo')
      }
      if (this.adicionais[produto.id + '-extra_presunto'] > 0) {
        extras_descricao.push('extra de presunto')
      }
      let valor_unitario = this.valores_finais[produto.id]/quantidade
      this.carrinho.push({produto: produto, extras: extras_descricao, tamanho: this.tamanhosSelecionados[produto.id], quantidade: quantidade, valor_unitario: valor_unitario, valor_total: this.valores_finais[produto.id] })
      console.table(this.carrinho)
      this.carrinhoTotal += this.valores_finais[produto.id]
      console.log("carrinho total = ", this.carrinhoTotal)
    },
    checkBoxClicked(event, tipo, produto, value) {

      if (event.target.checked) {
        this.adicionais[produto.id + '-'+ tipo] = parseFloat(value)
      } else {
        this.adicionais[produto.id + '-'+ tipo] = 0
      }
      
      this.calcularValorProduto(produto)

    },
    radioClicked(event, tipo, produto, tamanho) {

      if (tipo === 'produto') {
        this.valores[produto.id] = parseFloat(event.target.value)
        this.tamanhosSelecionados[produto.id] = produto.tamanho[tamanho]
        // console.log("tamanho selecionado", produto.tamanho[tamanho])
      }

      if (tipo === 'extra_leite') {
        let newExtra = event.target.value.split('-')
        this.adicionais[produto.id + '-extra_leite'] = parseFloat(newExtra[1])
      }
      
      this.calcularValorProduto(produto)

    },
    calcularValorProduto(produto) {

      console.table(this.adicionais)

      if (!this.adicionais[produto.id + '-extra_leite']) {
        this.adicionais[produto.id + '-extra_leite'] = 0
      }
      
      if (!this.adicionais[produto.id + '-extra_cafe']) {
        this.adicionais[produto.id + '-extra_cafe'] = 0
      }

      if (!this.adicionais[produto.id + '-extra_chantilly']) {
        this.adicionais[produto.id + '-extra_chantilly'] = 0
      }
      
      if (!this.adicionais[produto.id + '-extra_marshmallow']) {
        this.adicionais[produto.id + '-extra_marshmallow'] = 0
      }
      
      if (!this.adicionais[produto.id + '-extra_espuma']) {
        this.adicionais[produto.id + '-extra_espuma'] = 0
      }

      if (!this.adicionais[produto.id + '-extra_queijo']) {
        this.adicionais[produto.id + '-extra_queijo'] = 0
      }

      if (!this.adicionais[produto.id + '-extra_presunto']) {
        this.adicionais[produto.id + '-extra_presunto'] = 0
      }

      // console.log('valor final ', this.valores[produto.id])
      // console.log('adicional de leite = ', this.adicionais[produto.id + '-extra_leite'])

      this.valores_finais[produto.id] = this.valores[produto.id] + this.adicionais[produto.id + '-extra_leite']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_cafe']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_chantilly']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_marshmallow']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_espuma']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_queijo']
      this.valores_finais[produto.id] += this.adicionais[produto.id + '-extra_presunto']

      this.valores_finais[produto.id] = this.valores_finais[produto.id] * this.quantidades[produto.id]

      console.log('valor final + adicionais', this.valores_finais[produto.id])
    },
    recalcularQuantidade(produto, acao) {
      if (acao == 'mais') {
        this.quantidades[produto.id]++
      } else {
        this.quantidades[produto.id]--
      }
      if (this.quantidades[produto.id] < 1)
        this.quantidades[produto.id] = 1
      
        this.calcularValorProduto(produto)
    },
  },
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>